#container-drawer .drawer {
	.container-drawer-content {
		display: flex;
		flex-direction: row;
		height: 70%;
		padding-bottom: 1em;
		padding-top: 1em;
		overflow-y: auto;

		.hl-tag {
			padding: 0.2em 0.5em;
			max-height: 25px;
		}

		img {
			width: 30%;
		}

		.drawer__container-details {
			padding: 1em;
			width: 100%;
			display: flex;
			flex-direction: column;
			justify-content: space-between;

			.drawer_container_header {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
			}
		}

		button.edit {
			background: transparent;
			box-shadow: none;

			svg {
				width: 25px;
				height: 25px;
			}
		}
	}

	button {
		background: #12c698;
		box-shadow: inset 0 0 0 0.2rem #12c698;
	}
}

.drawer__buttons {
	display: flex;
	width: 100%;
	gap: 1em;
}
