.container_list_container {
}

@media (max-height: 700px) {
	#container_list .modal-container .modal {
		margin-top: 20%;
	}
}

#container_list {
	.modal-container {
		background: rgba(0, 0, 0, 0.8);

		.modal {
			margin-top: 35%;
		}
	}
}

.containerList {
	width: 90%;
	margin: 0 auto;
}

#ContainerSelector {
	display: flex;
	flex-direction: column;
	min-height: 90vh;

	.drawer {
		height: auto;
		z-index: 2;
	}

	.container-model-filter {
		margin: auto;
		width: 99%;

		input {
			width: 100%;
		}
	}

	&.disabled .drawerShadow {
		background: rgba(8, 8, 8, 0.32);
		position: fixed;
		top: 0;
		width: 100vw;
		height: 100vh;
		z-index: 1;
	}

	.Loader {
		margin: auto;
	}
}
