@import '../../../../lib/scss/variables';

#PhotoOn {
	height: 100%;
	button {
		z-index: 10;
		margin-top: 20px;
		display: flex;
		position: fixed;
	}
	button.flash-on,
	button.flash-off {
		right: 0;
		margin: 20px;
	}

	.back {
		left: 0;
		margin-left: 20px;
		fill: $black;
		background: transparent;

		svg {
			width: 100px;
			height: 60px;
		}
	}
}
