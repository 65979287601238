.active_contracts_list {
	display: flex;
	flex-wrap: wrap;
	gap: 0.5em;

	&__item {
		display: flex;
		flex-wrap: nowrap;
		align-items: flex-end !important;

		> * {
			height: 100%;
		}

		.container-list__sensor-container_serial-number {
			display: flex;
			align-items: center;
		}

		&__icon {
			width: 15px;
			margin-right: 3px;
			font-size: 15px;
		}
	}
}
