@use 'sass:color';

$main-color-blue: #4b8df9;
$main-color-blue-hover: color.adjust(
	$main-color-blue,
	$lightness: -10%,
	$space: hsl
);
$main-color-green: #00c392;

$sec-color-orange: #ff6d36;
$sec-color-yellow: #ffc16a;
$sec-color-lightblue: #e5f0fe;
$sec-color-blue: #292c7e;
$sec-color-purple: #ab7ce2;
$sec-color-pink: #ffc6f9;

$utility-color-info: #63b7f8;
$utility-color-warning: #ffc96b;
$utility-color-success: #38cf94;
$utility-color-error: #ff4858;

$black: #000000;
$white: #ffffff;
$color-gray-darker: #1e2948;
$color-gray-dark: #879399;
$color-gray: hsl(0, 0%, 50%);
$color-gray-light: #ebebeb;
$color-gray-lighter: #f4f7f9;

$basic-shadow: 0 6px 6px -2px rgba(230, 232, 237, 0.8);

$border-green-1: solid 1px $main-color-green;
$border-green-2: solid 2px $main-color-green;
$border-blue-1: solid 1px $main-color-blue;
$border-blue-2: solid 2px $main-color-blue;
$border-red-1: solid 1px #f22f3e;
$border-red-2: solid 2px #f22f3e;
$border-black-1: solid 1px $black;
$border-black-2: solid 2px $black;
$border-white-1: solid 1px $white;
$border-white-2: solid 2px $white;

$statusColor: (
	'ok': $utility-color-success,
	'warning': $utility-color-warning,
	'error': $utility-color-error
);

$mobile-breakpoint: 740px;
$mobile-xs-breakpoint: 600px;
