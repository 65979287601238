.hl-circle-text {
	@include circleIt(4rem);
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgba($main-color-blue, 0.3);
	color: $main-color-blue;
	text-transform: capitalize;
	font-weight: bold;
	fill: $main-color-blue;

	&__icon {
		font-size: 2rem;
	}

	&--large {
		@include circleIt(4.8rem);

		.hl-circle-text__icon {
			font-size: 2.4rem;
		}
	}

	&--icon {
		background-color: rgba($main-color-blue, 0.5);
	}

	&--small {
		@include circleIt(2.8rem);

		.hl-circle-text__icon {
			font-size: 1.6rem;
		}
	}

	@each $key, $value in $statusColor {
		&--#{$key} {
			color: $value;
			background-color: rgba($value, 0.3);

			.hl-circle-text__icon {
				fill: $value;
			}
		}
	}
}
