#container-form {
	display: flex;
	flex-direction: column;

	width: 90%;
	margin: 0 auto;

	h2 {
		margin-left: initial;
		padding: 10px 0;
		font-size: 20px;
	}

	button {
		margin-top: 1em;
	}

	.hl-input-text__input {
		width: 100%;
	}

	.hl-input-text__input:focus-visible {
		outline: 1px solid #d8e3f1;
	}

	.hl-input-text__label {
		margin-top: 0.5em;
	}

	label > div > div {
		margin: 0;
	}
}
