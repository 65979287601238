ul#containers_list {
	width: 99%;
	margin: auto;

	> li {
		margin: 2em 0;
		min-height: 50px;
		padding: 1em;
		box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.2);
		border-radius: 4px;
		background: #ffffff;

		&:focus {
			outline: none;
		}
	}

	.container_list_pav_item {
		background-color: #4b8df810;
	}

	.container_list_container_item {
		background-color: white;
	}
}
