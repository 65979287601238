@import '../../../../../lib/scss/variables';

.tags_checkbox_group {
	margin-top: 1em;
	border-color: $color-gray-lighter;

	legend {
		color: hsl(0, 0%, 50%);
		font-size: 1.4rem;
		font-weight: 500;
	}

	label {
		margin-bottom: 0.5em;
		display: block;
	}
}
