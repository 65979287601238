.bottom-sheet-wrapper {
	.backdrop {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, 0.6);
		transition: all 0.2s ease-in-out;

		opacity: 0;
		pointer-events: none;
	}

	label {
		display: block;
	}

	.bottom-sheet {
		background: #fff;
		width: 97%;
		min-height: 200px;
		position: absolute;
		z-index: 2;
		border-radius: 15px;
		transition: all 0.2s ease-in-out;

		display: flex;
		flex-direction: row;

		left: 50%;
		transform: translateX(-50%);

		bottom: -110%;
		img {
			align-self: center;
		}
		ul {
			min-height: 200px;
			display: flex;
			flex-direction: column;
			font-size: 2rem;
			font-weight: 500;
			flex: 1;
			justify-content: space-around;

			li {
				font-size: 2rem;
				font-weight: 500;

				button,
				label {
					width: 100%;
					padding: 1em;
					text-align: left;
					font-size: 15px;
					color: black;
				}

				input {
					position: fixed;
					left: -1000px;
				}
			}
		}
	}

	&.show-modal {
		.backdrop {
			opacity: 1;
			pointer-events: all;
		}

		.bottom-sheet {
			bottom: 5px;
		}
	}
}
