.previous-searched-ids {
	display: flex;
	gap: 1em;
	margin-bottom: 1em;
	flex-wrap: wrap;
	justify-content: space-between;

	button {
		width: 100%;
	}
}
