.cover {
	height: 35vh;
	margin-top: 5vh;

	img {
		width: 100%;
		height: 100%;
		object-fit: contain;
	}
}

@media screen and (max-height: 750px) {
	.cover {
		height: 30vh;
		margin-top: 8vh;
	}
}

@media (max-height: 700px) {
	.cover {
		height: 20vh;
		margin-top: 5vh;
	}
}

@media (max-height: 500px) {
	.cover {
		display: none;
	}
}
