@import '../../../lib/scss/variables';

.comments-page {
	min-height: 80vh;
}
.comment-photo {
	position: absolute;
	top: 0;
	left: 0;
	background: white;
	z-index: 1000;
}
.comments-wrapper {
	display: flex;
	flex-direction: column;
	height: 85vh;
	overflow-y: auto;
	scrollbar-width: none;

	> ul {
		min-height: auto;
		flex: 1 0 auto;

		li {
			background: $color-gray-lighter;
			border-radius: 18px;
			padding: 1em;
			margin: 1em 0;

			p:first-of-type {
				margin: 0 0 1em 0;
			}

			p:last-of-type {
				font-size: smaller;
			}

			img.loading {
				opacity: 0.2;
			}
		}
	}

	form {
		display: flex;
		padding: 1em 0;
		gap: 1em;

		input {
			flex: 2;
		}
	}
}
