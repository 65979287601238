@import '../../lib/scss/variables';

#WizardSummary {
	svg {
		height: 20px;
	}

	dl {
		display: flex;
		flex-flow: row;
		flex-wrap: wrap;
		width: 100%;
		height: 100%;
		overflow: visible;
		font-size: 13.5px;
		margin: 0 auto;
		padding: 10px;
	}

	.dl-container {
		flex-wrap: nowrap;

		dd:nth-child(3) {
			display: block;
		}
	}

	dl.column {
		display: flex;
		flex-direction: column;
		margin-left: 0;
		text-align: start;
		width: 100%;

		dt {
			flex: none;
			margin: 0;
		}

		dd {
			&:nth-child(2) {
				margin-bottom: 0.5em;
			}

			> img {
				width: 60px;
				height: 60px;
				object-fit: cover;
			}

			.container {
				display: flex;
				flex-direction: column;
				margin-left: 1em;
				width: 100%;
				overflow-x: auto;
				padding: 0.5em 0;

				h3 {
					font-weight: 600;
					font-size: 1.2em;
					display: block;
					word-break: break-all;
					width: 100%;
				}

				p {
					display: flex;

					span {
						font-size: 1rem;
						width: 100%;
						white-space: normal;
						font-weight: normal;
					}
				}

				ul {
					display: flex;
					flex: 1 50%;
					justify-content: center;

					li {
						margin-left: 1em;
					}
				}
			}
		}

		.goloc-icon {
			width: 20px;
			height: 20px;
		}
	}

	dt {
		flex: 0 0 50%;
		color: $main-color-blue;
		font-weight: 500;
		text-overflow: ellipsis;
		margin: auto;
	}

	dd {
		flex: 0 0 50%;
		margin-left: auto;
		text-overflow: ellipsis;
		text-align: end;
		font-weight: 500;
	}

	.summary {
		display: flex;
		flex-direction: column;
		height: 79vh;
		margin-bottom: 2em;

		&__blocks {
			overflow: scroll;
			display: flex;
			justify-content: space-between;
			flex-direction: column;
		}

		h2 {
			margin: 2em 0 1em 0;
		}

		p {
			font-size: 1.5em;
		}

		&__contract-report {
			padding: 0 1rem;
			border-radius: 0.4rem;
			background-color: $color-gray-lighter;
			color: $color-gray-darker;
			text-overflow: ellipsis;
			white-space: normal;
			display: flex;
			justify-content: space-between;
			margin-top: 0.3em;
			margin-bottom: 0.3em;

			span {
				font-family:
					Gotham Rounded,
					sans-serif;
				font-size: 1.5rem;
				font-weight: 500;
			}

			.container {
				overflow-wrap: break-word;
				width: 75%;
			}
		}

		&__wizard-note {
			.column {
				flex-wrap: initial;
			}

			dt {
				display: flex;
				justify-content: space-between;
			}

			p {
				font-size: 16px;
				display: block;
				overflow: scroll;
				word-break: break-all;
			}

			&_button {
				background: $main-color-blue;
				padding: 10px;
				color: $white;
				font-weight: 500;
				border-radius: 5px;
				width: 100%;
				font-size: 14px;
				margin-top: auto;
			}
		}
	}
}

@media (max-height: 500px) {
	#WizardSummary .summary {
		height: 72vh;
	}
}
