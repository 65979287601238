body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	--primary-color: #4b8df8;

	overscroll-behavior: none;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

video {
	position: inherit;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	min-width: 100%;
	min-height: 100%;
	z-index: -1;
	-webkit-transition: all 1s;
	-moz-transition: all 1s;
	-o-transition: all 1s;
	transition: all 1s;
}

.page {
	display: flex;
	flex-direction: column;
	padding: 0 2em;
	min-height: 100vh;
}

.page.dynamic-height {
	min-height: 0;
}

.main-layout {
	> div {
		height: 40vh;
	}
	> div:nth-child(1) {
		margin-top: 5vh;
	}
}

button.btn--white {
	background-color: transparent;
	box-shadow: inset 0 0 0 0.2rem transparent;
	text-transform: none;
	color: black;
	fill: transparent;
	svg {
		fill: black;
	}
}
