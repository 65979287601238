@import '../../lib/scss/variables';

#WizardMeasure {
	#Pass {
		position: absolute;
		right: 50px;
		top: 20px;
		font-size: 2rem;
		font-weight: 500;
		color: grey;
	}

	#measure {
		p {
			border: none;
			padding: 1rem;
			border-radius: 0.4rem;
			background-color: $color-gray-lighter;
			color: #1e2948;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
			display: flex;
			justify-content: space-between;
			span {
				font-family:
					Gotham Rounded,
					sans-serif;
				font-size: 1.5rem;
				font-weight: 500;
			}
		}
	}

	> div {
		h2 {
			margin: 1em 0 0 0;
		}
		p:nth-child(3) {
			font-size: 1.8rem;
		}
		strong {
			font-weight: 600;
		}
	}
}

@media (max-height: 500px) {
	#WizardMeasure {
		#Pass {
			position: initial;
			display: block;
			text-align: right;
			margin-top: 1em;
		}
	}
	.measure-indoor {
		.wizard-page__content {
			height: 65vh;
			&_title {
				margin: 0 !important;
			}
		}
	}
}
