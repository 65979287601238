.container-list-item-block {
	.container-list-item-header {
		display: flex;
		margin-bottom: 1em;
		gap: 0.5em;

		h3 {
			color: var(--primary-color);
			font-weight: 700;
			font-size: 1.3em;
			flex: 1 50%;
			word-break: break-all;
		}
	}

	.container-list-item-body {
		display: flex;
		flex-wrap: wrap;
		gap: 0.5em;

		p {
			color: grey;
			font-weight: 400;
		}

		img {
			width: 15px;
			margin-right: 10px;
		}
	}

	.hl-tag {
		padding: 0.2em 0.5em;
		max-height: 25px;
	}
}
