$var: 4rem 12.8rem 10rem 22rem auto 1.6rem;

.hl-user-list-row {
	padding: 1.6rem 2rem;
	grid-template-columns: $var;
	@include rowBackground();

	&__title {
		font-weight: bold;
	}

	&__actions-btn {
		margin-left: auto;
		@include rowActionBtn();
	}

	&__col {
		font-weight: 500;
	}
}
